<template>
  <div class="backclick">
    <div class="logo-container">
      <a href="https://www.clickdefense.io">
        <img src="@/assets/images/logo/clickdefense-logo-small-dark.png">
      </a>
    </div>
    <div data-v-2b7575d8="" errormessage="msgAuth">
      <div data-test="sign-in-section" class="Form__formSection___3tqxz">
        <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">¡Hemos terminado!</div>
        <div class="form-help-text">
          <p>Vamos a configurar tu cuenta antes de que accedas al panel de control</p>
        </div>
        <div>
          <h5>Comprobando la cuenta de Google Ads</h5>
          <b-progress :value="checkStep1Value" :animated="checkStep1Animated" :variant="checkStep1Variant" :max="checkmaxVal"></b-progress>
          <h5 class="check-step-label">Obteniendo las cuentas de Google Ads</h5>
          <b-progress :value="checkStep2Value" :animated="checkStep2Animated" :variant="checkStep2Variant" :max="checkmaxVal"></b-progress>
          <h5 class="check-step-label">Configurando el panel de control</h5>
          <b-progress :value="checkStep3Value" :animated="checkStep3Animated" :variant="checkStep3Variant" :max="checkmaxVal"></b-progress>
        </div>
        <div data-test="sign-in-footer-section" class="Section__sectionFooter___2QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="sign-in-sign-in-button" class="Button__button___1FrBC btn-continue bottom-button btn-disabled" disabled>Continuar</button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  data() {
    return {
      checkmaxVal: 100,
      checkStep1Value: 100,
      checkStep2Value: 100,
      checkStep3Value: 70,
      checkStep1Animated: true,
      checkStep2Animated: true,
      checkStep3Animated: true,
      checkStep1Variant: 'success',
      checkStep2Variant: 'success',
      checkStep3Variant: 'warning'
    }
  },
  async mounted() {
    const urlParams = this.$route.query
    if (!urlParams.sessionid) {
      this.removeLocalStprageData()
      this.setStatusUI()
      this.$router.push("/auth")
      return
    }
    const cdSession = localStorage.getItem('session')
    if (cdSession && cdSession !== '') {
      try {
        const cdSessionObj = JSON.parse(atob(cdSession))
        const ema = cdSessionObj['9s75CYh37vyEpUHSJJmO']
        const use = cdSessionObj['lHjY5TwQcSV4G4U4KXIS']
        const pwd = cdSessionObj['nvk5IPDf8xCRrMOZial1']
        if (ema && use && pwd) {
          if (use === urlParams.sessionid) {
            this.removeLocalStprageData()
            try {
              const user = await Auth.signIn(ema, pwd)
              if (user) {
                this.setStatusUI('ok')
                this.$router.push("/")
                return
              }
            } catch (err) {
              this.$vs.loading.close()
              this.setStatusUI()
              this.$router.push("/auth")
              return
            }
          } else {
            this.removeLocalStprageData()
            this.$vs.loading.close()
            this.setStatusUI()
            this.$router.push("/auth")
            return
          }
        }
      } catch (err) {
        this.removeLocalStprageData()
        this.$vs.loading.close()
        this.setStatusUI()
        this.$router.push("/auth")
        return
      }
    } else {
      this.removeLocalStprageData()
      this.$vs.loading.close()
      this.setStatusUI()
      this.$router.push("/auth")
    }
  },
  beforeDestroy() {
    clearInterval(this.timeoutProgress)
    this.timeoutProgress = null
  },
  methods: {
    removeLocalStprageData() {
      localStorage.removeItem('session')
    },
    setStatusUI(dataStatus = 'error') {
      if (dataStatus === 'ok') {
        this.checkStep3Animated = false
        this.checkStep3Value = 100
        this.checkStep3Variant = 'success'
      } else {
        this.checkStep3Animated = false
        this.checkStep3Value = 100
        this.checkStep3Variant = 'danger'
      }
    }
  }
}
</script>

<style scoped>
#app > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#app > div > div:nth-child(2) {
  margin: 0 auto;
}
.logo-container {
  width: 100%;
  text-align: center;
  margin: 1% auto;
}
</style>
<style>
.texthead {
  font-weight: bold;
}
.textdisabled {
  color: gainsboro;
}
.butdisabled {
  background-color: rgba(125, 78, 197, 0.5) !important;
}
.textar {
  width: 100%;
  margin: 10px 0px;
}
.textar > div {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}
#conditions {
  height: 250px;
  text-align: justify;
  margin: 1% 0;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.Form__formSection___3tqxz {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 50vw;
  min-width: 360px;
  padding: 20px;
}
.Section__sectionBody___3DCrX {
  margin-bottom: 0;
}
.Section__sectionHeader___13iO4 {
  text-align: center;
}
.scrolldowncheck {
  margin-top: 20px;
}
.error {
  color: #ff0000;
}
.backclick {
  background-image: url(../../assets/images/pages/bg-login.png);
  background-size: cover;
  min-height: 100vh;
}
.Input__inputLabel___nQolz span {
  color: #737373;
  margin-left: 20px;
}
.form-help-text p {
  color: #313131;
  margin-bottom: 3rem;
}
.form-steps-list li span {
  color: #313131;
  margin-left: 1rem;
}
.form-steps-list li {
  margin-bottom: 10px;
}
.btn-continue {
  background-color: rgb(125, 78, 197);
  float: right;
  outline: none !important;
}
.btn-back {
  background-color: rgb(125, 78, 197);
  float: left;
  outline: none !important;
}
.input-format-msg {
  font-size: 0.9rem;
  margin-left: 10px;
}
.form-steps-center {
  text-align: center;
}
.modal-header {
  color: #ffffff;
  background-color: #e4dfff;
}
.modal-button {
  background-color: rgb(125, 78, 197);
  border-color: rgb(125, 78, 197);
}
.modal-body strong {
  margin-right: 10px;
}
.modal-body li {
  margin-bottom: 10px;
}
.check-step-label {
  margin-top: 3rem;
}
.bottom-button {
  margin-top: 2rem;
}
.btn-disabled {
  opacity: 0.8;
}
</style>