var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "backclick" }, [
    _vm._m(0),
    _c("div", { attrs: { "data-v-2b7575d8": "", errormessage: "msgAuth" } }, [
      _c(
        "div",
        {
          staticClass: "Form__formSection___3tqxz",
          attrs: { "data-test": "sign-in-section" }
        },
        [
          _c(
            "div",
            {
              staticClass: "Section__sectionHeader___13iO4",
              attrs: { "data-test": "sign-in-header-section" }
            },
            [_vm._v("¡Hemos terminado!")]
          ),
          _vm._m(1),
          _c(
            "div",
            [
              _c("h5", [_vm._v("Comprobando la cuenta de Google Ads")]),
              _c("b-progress", {
                attrs: {
                  value: _vm.checkStep1Value,
                  animated: _vm.checkStep1Animated,
                  variant: _vm.checkStep1Variant,
                  max: _vm.checkmaxVal
                }
              }),
              _c("h5", { staticClass: "check-step-label" }, [
                _vm._v("Obteniendo las cuentas de Google Ads")
              ]),
              _c("b-progress", {
                attrs: {
                  value: _vm.checkStep2Value,
                  animated: _vm.checkStep2Animated,
                  variant: _vm.checkStep2Variant,
                  max: _vm.checkmaxVal
                }
              }),
              _c("h5", { staticClass: "check-step-label" }, [
                _vm._v("Configurando el panel de control")
              ]),
              _c("b-progress", {
                attrs: {
                  value: _vm.checkStep3Value,
                  animated: _vm.checkStep3Animated,
                  variant: _vm.checkStep3Variant,
                  max: _vm.checkmaxVal
                }
              })
            ],
            1
          ),
          _vm._m(2)
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-container" }, [
      _c("a", { attrs: { href: "https://www.clickdefense.io" } }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/logo/clickdefense-logo-small-dark.png")
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-help-text" }, [
      _c("p", [
        _vm._v(
          "Vamos a configurar tu cuenta antes de que accedas al panel de control"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "Section__sectionFooter___2QlDn",
        attrs: { "data-test": "sign-in-footer-section" }
      },
      [
        _c(
          "span",
          { staticClass: "Section__sectionFooterPrimaryContent___36Gb1" },
          [
            _c(
              "button",
              {
                staticClass:
                  "Button__button___1FrBC btn-continue bottom-button btn-disabled",
                attrs: { "data-test": "sign-in-sign-in-button", disabled: "" }
              },
              [_vm._v("Continuar")]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }